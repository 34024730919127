.container {
    display: flex;
    align-items: center;
}

.title {
    display: flex;
    align-items: center;
    margin-top: 50px;
}

.input {
    margin-left: 13px;
    width: 50px;
}

.message {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.textarea {
    margin-left: 5px;
    width: 350px;
}

.city {
    margin-top: 20px;
}

.selector {
    margin-left: 13px;
}

.sendButton {
    margin-top: 20px;
}
